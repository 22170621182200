import React from 'react'
import styled from 'styled-components';
import Container from './layout/Container';
import Section from './layout/Section';
import {SectionTitle, SectionHeader, P, A} from './elements';
import ContactIllustration from './svg/ContactIllustration';
import formatePhoneNumber from '../utils/formatePhoneNumber';
import { colors, breakpoints } from '../styles/_variables';
import serviceData from '../../content/service.yaml';

const IllustionWrp = styled.div.attrs({
  className: `absolute w-40`,
})`
  top: -3em;
  right: 0;
  @media screen and (min-width: ${breakpoints.md}) {
    top: 2em;
    right: 8%;
  }
`;
const SectionHeaderC = styled(SectionHeader)`
  max-width: 70%;
  @media screen and (min-width: ${breakpoints.md}) {
  }
`;

const PhoneNumbers = styled.div.attrs({
  className: `flex justify-between mt-8 md:w-3/5 lg:w-2/5`
})`
  color: ${colors.primary};
`;

interface ContactInfo {
  title: string;
  heading: string;
  description: string;
  ph1: number;
  ph2: number;
}

const Contact: React.FC = () => {
  const contactInfo: ContactInfo = serviceData.contact;
  return (
    <Section bg={colors.bg_white}>
      <Container>
        <SectionTitle>{contactInfo.title}</SectionTitle>
          <IllustionWrp>
            <ContactIllustration />
          </IllustionWrp>
        <div className="pt-16 md:pt-4" />
        <SectionHeaderC>{contactInfo.heading}</SectionHeaderC>
        <P color={colors.text_gray}>{contactInfo.description}</P>
        <PhoneNumbers>
          <A href={`tel:${formatePhoneNumber(contactInfo.ph1)}`}>
            {formatePhoneNumber(contactInfo.ph1)}
          </A>
          <A href={`tel:${formatePhoneNumber(contactInfo.ph1, false)}`}>
            {formatePhoneNumber(contactInfo.ph2)}
          </A>
        </PhoneNumbers>
      </Container>
    </Section>
  )
}

export default Contact;