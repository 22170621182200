import React, { ReactNode } from 'react'
import styled from 'styled-components';
import Container from '../layout/Container';
import Section from '../layout/Section';
import {SectionHeader, SectionTitle} from '../elements';
import {
  colors,
  breakpoints,
} from '../../styles/_variables';
import {
  custom_scrollbar
} from '../../styles/_mixins';
import ContributionCard from './ContributionCard';
const profileImg1 = require('../../assets/svg/profile1.svg');
const profileImg2 = require('../../assets/svg/profile2.svg');
const profileImg3 = require('../../assets/svg/profile3.svg');
const profileImg4 = require('../../assets/svg/profile4.svg');

const Content = styled.div.attrs({
  className: `mt-4 flex flex-col md:flex-row
  md:overflow-x-scroll md:rounded-md`
})`
  ${custom_scrollbar};
`;

const amounts = [1000, 1500, 2500, 5000];
const months = [12, 24];
const recentContribution = [
  {amount: amounts[Math.floor(Math.random() * 4)], months: months[Math.floor(Math.random() * 2)]},
  {amount: amounts[Math.floor(Math.random() * 4)], months: months[Math.floor(Math.random() * 2)]},
  {amount: amounts[Math.floor(Math.random() * 4)], months: months[Math.floor(Math.random() * 2)]},
  {amount: amounts[Math.floor(Math.random() * 4)], months: months[Math.floor(Math.random() * 2)]}
];

type Props = {
  title: string;
  heading: string;
  children?: ReactNode;
}

const RecentContributions: React.FC = (props: Props) => {
  const {data: {title, heading}} = props;
  return (
    <Section bg={colors.bg_secondary}>
      <Container>
        <SectionTitle>{title}</SectionTitle>
        <SectionHeader>{heading}</SectionHeader>
        <Content>
          <ContributionCard contribution={recentContribution[Math.floor(Math.random() * 4)]} />
          <ContributionCard contribution={recentContribution[Math.floor(Math.random() * 4)]} />
          <ContributionCard contribution={recentContribution[Math.floor(Math.random() * 4)]} />
          <ContributionCard contribution={recentContribution[Math.floor(Math.random() * 4)]} />
        </Content>
      </Container>
    </Section>
  )
}

export default RecentContributions;