import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import {Button, P3, Spinner, Input} from '../elements';
import { live_plans, test_plans, live_key, test_key, } from '../../api/constants';
import razorpay from '../../api/razorpay';
import { useAxios } from '../../hooks';
import {DurationEnm, DonationTypes} from './enums';
import {
  colors,
  breakpoints,
} from '../../styles/_variables';
import {
  responsive_font,
} from '../../styles/_mixins';
const favicon = require('../../assets/images/fav.ico');
// import donateContent from '../../../content/donate.yaml';

const Duration = styled.div.attrs({
  className: `w-full flex`
})`
  /* ${responsive_font('body3')}; */
  @media screen and (min-width: ${breakpoints.md}) {
    ${responsive_font('body2')};
  }
`;
const Label = styled.label.attrs({
  className: `w-full`
})`
`;
const Amounts = styled.div.attrs({
  className: `my-6 rounded-md`
})`
  background-color: ${colors.bg_white};
`;
const AmountOption = styled.div.attrs({
  className: `amount-option
    relative w-full p-6`
})`
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.primary};
  }
  &:hover {
    .amount {
      font-weight: bold;
    }
  }
`;
const AmountWrapper = styled.div.attrs({
  className: `maximized-amount
    flex flex-row justify-start items-center ml-12`,
})`
  cursor: pointer;
  flex-wrap: wrap;
  & p {
    flex-shrink: 0;
    margin-bottom: 0;
    &:first-child {
      margin-right: 1em;
    }
    &:last-child {
      color: ${colors.text_gray}
    }
  }
`;
const Radio = styled.input.attrs({
  type: "radio",
  name: "amount",
  className: `radio-button block mt-3 mr-4 invisible`,
})`
  float: left;
  &:checked ~ .custom-radio {
    border-color: ${colors.primary};
    &::after {
      transform: scale(1);
    }
  }
  &:not(:checked) {
    & ~ .maximized-amount .feed {
      display: none;
    }
    & ~ .maximized-amount .amount {
      color: ${colors.text_gray};
    }
    & ~ .maximized-content {
      visibility: hidden;
    }
  }
  &:checked ~ .maximized-content {
    opacity: 1;
    height: auto;
    visibility: visible;
    transform: translateY(0em);
    transition: opacity 0.25s ease-out, transform 0.25s ease-out;
  }
  &:checked ~ .maximized-amount .amount {
    font-weight: bold;
  }
`;
const CustomRadio = styled.span.attrs({
  className: `custom-radio 
  absolute ml-6 mt-6 md:mt-7 lg:mt-6`
})`
  cursor: pointer;
  top: 0em;
  left: 0em;
  height: 28px;
  width: 28px;
  background-color: #eee;
  border-radius: 50%;
  border: 2px solid ${colors.gray};
  &::after {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${colors.primary};
    transform: scale(0);
    transition: transform 0.20s ease-in-out;
  }
`;
const Maximized = styled.div.attrs({
  className: 'maximized-content'
})`
  visibility: visible;
  opacity: 0;
  height: 0;
  transform: translateY(2em);
  transition: opacity 0.25s ease-out, transform 0.15s ease-out;
`;
const Wrapper = styled.div`
  color: ${colors.text_black};
`;

interface Props {
  setDuration: React.Dispatch<React.SetStateAction<DurationEnm>>;
  duration: DurationEnm;
};
type Amount = {
  amount: number;
  feed: string;
}

const Commitment: React.FC<Props> = ({data}) => {
  const [amount, setAmount] = useState<number>(500);
  const [isCustomAmount, setIsCustomAmount] = useState<boolean>(false);
  const [customAmount, setCustomAmount] = useState<number>(2000);
  const [duration, setDuration] = useState(DurationEnm.Half);
  const [donationType, setDonationType] = useState(DonationTypes.Once);
  const [invokeCall, response, isLoading, error] = useAxios();

  useEffect(() => {
    typeof window !== `undefined` && localStorage.removeItem('subscriptionUrl');
  }, []);

  useEffect(() => {
    console.log(response);
    if(response && response.short_url){
      const subscriptionOptions = {
        "key": live_key,
        "subscription_id": response.id,
        "name": "EkAurEk",
        "description": `Donate for ${duration} months`,
        "image": favicon,
        "handler": function(response) {
          window.location.href = `/thank-you?amount=${isCustomAmount? customAmount : amount}&duration=${duration}`;
          console.log(response.razorpay_subscription_id);
        },
        "theme": {
          "color": colors.bg_primary,
        }
      };
    const rzp1 = new Razorpay(subscriptionOptions);
    rzp1.open();
    }
  }, [response]);
  useEffect(() => {
    console.log(amount);
  }, [amount]);

  const amounts: Array<Amount> = [
    {amount: 500, feed: 'Feed 2 Citizens for a Month'},
    {amount: 1000, feed: 'Feed 4 Citizens for a Month'},
    {amount: 2500, feed: 'Feed 10 Citizens for a Month'},
    {amount: 5000, feed: 'Feed 20 Citizens for a Month'},
  ];
  const handleOptionClick = useCallback((e) => {
    const targetRadio = 
      e.target.querySelector('.radio-button')
      || e.target.parentNode.querySelector('.radio-button')
      || e.target.parentNode.parentNode.querySelector('.radio-button');
    setIsCustomAmount(false);
    setAmount(targetRadio.dataset.amount);
    targetRadio.checked = true;
  }, []);

  const handleActionButtonClick = useCallback(() => {
    if(donationType === DonationTypes.Once) {
      donateOnce();
      return;
    }
    const options = {
      plan_id: live_plans[amount],
      months: duration,
    };
    invokeCall(async () => await razorpay.post('subscription', JSON.stringify(options)));
  }, [duration, donationType, amount]);

  const donateOnce = () => {
    let options = {
      "key": live_key,
      "amount": (isCustomAmount? customAmount : amount) * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": "Ekaurek",
      "description": "Donate once",
      "image": favicon,
      "handler": function(response) {
        window.location.href = `/thank-you?amount=${isCustomAmount? customAmount : amount}&duration=once`;
        console.log(response.razorpay_subscription_id);
      },
      "theme": {
        "color": colors.bg_primary,
      }
  };
  const rzp1 = new Razorpay(options);
    rzp1.open();
  
  }

  const handleCustomSubscription = (e) => {
    const form: HTMLFormElement | null = document.querySelector('.custom-amount-form');
    if(form?.checkValidity()) {
      e.preventDefault();
    }
    if(customAmount < 100 || customAmount > 100000) {
      return false;
    }
    if(donationType === DonationTypes.Once) {
      donateOnce();
      return;
    }
    invokeCall(async () => {
      const planResponse = await razorpay.post('plan', JSON.stringify({amount: customAmount * 100}));
      const plan = planResponse.data;
      console.log(plan, plan.id);
      return await razorpay.post('subscription', JSON.stringify({
        plan_id: plan.id,
        months: duration,
      }));
    });
  }

  return (
    <Wrapper>
      <Duration>
        <Button full left
          title="12 months"
          inactive={donationType !== DonationTypes.Once}
          onClick={() => setDonationType(DonationTypes.Once)}
        >
          Once
        </Button>
        <Button
          full right 
          inactive={donationType !== DonationTypes.Monthly}
          title="12 months" 
          onClick={() => setDonationType(DonationTypes.Monthly)}
        >
          Monthly
        </Button>
      </Duration>

      <Amounts>
        {amounts.map((it, i) => (
          <AmountOption key={i}
            onClick={handleOptionClick}
          >
            <Label>
              <Radio defaultChecked={i===0? true: false} data-amount={it.amount} />
              <CustomRadio />
              <AmountWrapper>
                <P3 className="amount"
                  style={{cursor: 'pointer'}}
                >
                  {`₹${it.amount} / month`}
                </P3>
                <P3 fz="small" fw="bold" className="feed pt-1">{it.feed}</P3>
              </AmountWrapper>
              <Maximized>
                <Button 
                  full className="mt-3" 
                  onClick={handleActionButtonClick}
                  disabled={isLoading}
                >
                  continue &nbsp;&nbsp; 
                  <Spinner loading={isLoading} color={colors.text_white} />
                </Button>
                <P3 color={colors.text_gray} fz="small" className="pt-3" noMargin>
                  {data.paymentNote}
                </P3>
              </Maximized>
            </Label>
          </AmountOption>
        ))}
        {/* Other option */}
        <AmountOption
          onClick={() => setIsCustomAmount(true)}
        >
          <Label>
            <Radio/>
            <CustomRadio />
            <AmountWrapper>
              <P3 className="amount"
                style={{cursor: 'pointer'}}
              >
                {`Other Amount`}
              </P3>
            </AmountWrapper>
            <Maximized>
              <form className="mt-4 custom-amount-form">
                <Input className="amount-input" label="₹" type="number" fw="bold" placeholder="Enter Amount" 
                  min="100" max="100000"
                  onChange={(e) => {
                    setCustomAmount(e.target.value);
                  }}
                  value={customAmount}
                />
                <Button full className="mt-3"
                  onClick={handleCustomSubscription}
                >
                  continue &nbsp;&nbsp;
                  <Spinner loading={isLoading} color={colors.text_white} />
                </Button>
              </form>
              <P3 color={colors.text_gray} fz="small" className="pt-3" noMargin>
                {data.paymentNote}
              </P3>
            </Maximized>
          </Label>
        </AmountOption>
      </Amounts>
    </Wrapper>
  )
}

export default React.memo(Commitment);
