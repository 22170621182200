import React, {useState, useEffect, useCallback} from "react";
import axios from 'axios';

const  useAxios =() => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const invokeCall = useCallback( async cb => {
    setIsLoading(true);
    try {
      const res = await cb();
      // console.log(res);
      setResponse({...res.data});
      setIsLoading(false);
      setError(null);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }, []);

  return [invokeCall, response, isLoading, error];
}

export default useAxios;


/* 
usage
--------

*/
