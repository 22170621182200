import React, {useState, useEffect} from 'react'
import styled from 'styled-components';
import Container from '../layout/Container';
import {SectionTitle as Title, SectionHeader, P, Img} from '../elements';
import {
  layout,
  colors,
  breakpoints,
} from '../../styles/_variables';
import Commitment from './Commitment';
import {DurationEnm} from './enums';

const Section = styled.section`
  padding-bottom: ${layout.section_padding_mobile_y};
  position: relative;
  color: ${colors.text_black};
  background-color: ${colors.bg_primary};
  @media screen and (min-width: ${breakpoints.md}) {
    background-color: ${colors.none};
    color: ${colors.text_white};
  }
`;
const SectionTitle = styled(Title).attrs({
  className: `hidden md:block`
})`
`;
const SectionTitleMobile = styled(Title).attrs({
  className: `block md:hidden`
})`
`;
const DonateContainer = styled.div.attrs({
  className: `relative`
})`
  z-index: 1;
  padding-top: 8vmin;
  width: 100%;
  @media screen and (min-width: ${breakpoints.md}) {
    width: 500px;
  }
  @media screen and (min-width: ${breakpoints.lg}) {
    width: 540px;
  }
`;
const ImgC = styled(Img).attrs({
  className: `h-full w-full`,
  imgStyle: { objectFit: 'cover', objectPosition: "75% 50%", },
})`
  display: none;
  @media screen and (min-width: ${breakpoints.md}) {
    display: block;
  }
`;
const BGImg = styled.div.attrs({
  className: `w-full h-full absolute`
})`
  z-index: -1;
  background-image: url(${p => p.image.childImageSharp.fluid.src});
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: cover;
`;

export default function Donate({data}) {
  return (
    <Section>
      <BGImg image={data.donate.bgImage} />
      <Container>
        <DonateContainer>
          <SectionTitle lineColor={colors.text_white}>{data.donate.title}</SectionTitle>
          <SectionTitleMobile >{data.donate.title}</SectionTitleMobile>
          <SectionHeader>{data.donate.heading}</SectionHeader>
          <P>{data.donate.description}</P>
          <Commitment data={data.donate} />
        </DonateContainer>
      </Container>
    </Section>
  )
}
