const live_plans = {
  500: 'plan_G8eHnyh3gTtZ6u',
  1000: 'plan_FDyHSW2HyPe9Mt',
  1500: 'plan_FDyGpnHYlvSQ3e',
  2500: 'plan_FDyHGrzEVkduYY',
  5000: 'plan_FDyHdjqVxw5C5E',
}

const test_plans = {
  1000: 'plan_FAj6mlUC1Rb1PV',
  1500: 'plan_FAj6v77BWjVAOF',
  2500: 'plan_FAj71VBh0cYjNU',
  5000: 'plan_FAj78QSwzRK9cb',
};

const live_key = "rzp_live_tsjZM3TTMrDGDY";
const test_key = "rzp_test_4XGOQTlxq8cBek";

module.exports = {
  live_plans,
  test_plans,
  live_key,
  test_key,
}