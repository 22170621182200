function formatePhoneNumber(ph: number, dashed=true): string | ErrorConstructor {
  let phNumber = ph.toString();
  if(phNumber.length !== 10 && phNumber.length !== 12) {
    throw new Error('Not a valid phone number');
  }
  phNumber = phNumber.length === 10? `+91${phNumber}` : `+${phNumber}`;
  const h1 = phNumber.length-4;
  const h2 = phNumber.length-7;
  const hyphenedNumber = insertCharacter(insertCharacter(phNumber, '-', h1), '-', h2);
  if(dashed) {
    if(phNumber.length === 10) {
      return `${hyphenedNumber}`
    } else {
      return `${insertCharacter(hyphenedNumber, ' ', 3)}`
    }
  }
  return phNumber;
}

export default formatePhoneNumber;

function insertCharacter(str: string, subString: string, index: number): string {
  return str.slice(0, index)+subString+str.slice(index);
}

