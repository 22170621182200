import React, { ReactNode } from 'react'
import styled from 'styled-components';
import {SectionHeader} from '../elements';
import {
  colors,
  breakpoints,
} from '../../styles/_variables';
import {
  responsive_font,
} from '../../styles/_mixins';
const profileImg1 = require('../../assets/svg/profile1.svg');
const profileImg2 = require('../../assets/svg/profile2.svg');
const profileImg3 = require('../../assets/svg/profile3.svg');
const profileImg4 = require('../../assets/svg/profile4.svg');

const Contribution = styled.div.attrs({
  className: `flex items-center py-4 px-6 w-full rounded-lg mb-3 md:mr-6 md:w-1/3
  `
})`
  background-color: ${colors.bg_white};
  flex-shrink: 0;
  * {
    margin: 0;
  }
`;
const ProfileImg = styled.div.attrs({
  className: `w-2/5 pr-6`
})`
  max-width: 120px;
`;
const Period = styled.p.attrs({
  className: `uppercase pt-4`
})`
  ${responsive_font('small')};
`;

const profileImgs = [profileImg1, profileImg2, profileImg3, profileImg4, ];

type Props = {
  contribution: {
    amount: number;
    months: number;
  },
  children?: ReactNode;
}

const ContributionCard: React.FC = (props: Props) => {
  const { contribution: { amount, months } } = props;
  return (
    <Contribution>
        <ProfileImg>
          <img src={profileImgs[Math.floor(Math.random() * 4)]} />
        </ProfileImg>
        <div className="flex-col">
          <p>
            <SectionHeader className="inline-block">
              {amount}
            </SectionHeader>
            / month
          </p>
          <Period>{months} months commitment</Period>
        </div>
    </Contribution>
  )
}

export default ContributionCard;