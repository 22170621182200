import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import tw from "twin.macro";
import styled from "styled-components";
import Layout from "../components/PageLayout";
import Image from "../components/image";
import SEO from "../components/seo";
import Donate from '../components/donate';
import Contact from '../components/Contact';
import FAQ from '../components/FAQ';
import {HeaderOffset} from '../components/elements';
import RecentContributions from '../components/donate/RecentContributions';


const H1 = styled.h1.attrs({
  className: `bg-gray-200 `,
})`
  ${tw` py-4 flex`};
`;

const IndexPage = () => {
  const query = useStaticQuery(graphql`
    query donateQuery {
      markdownRemark(frontmatter: {content: {eq: "donate"}}) {
        frontmatter {
          donate {
            title
            heading
            description
            paymentNote
            bgImage {
              childImageSharp {
                fluid(maxWidth: 2048, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          contributions {
            title
            heading
          }
        }
      }
    }
  `);
  const data = query.markdownRemark.frontmatter;
  return (
    <Layout>
      <SEO title="Donate" />
      <HeaderOffset />
      <Donate data={data} />
      <RecentContributions data={data.contributions} />
      <Contact />
      <FAQ />
    </Layout>
  )
};

export default IndexPage;
