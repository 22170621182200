import React from 'react'

export default function ContactIllustration() {
  return (
<svg width="100%" height="100%" viewBox="0 0 163 257">
    <g id="Donate" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="1.1-Desktop-Donate" transform="translate(-1023.000000, -1615.000000)">
            <g id="Group-16" transform="translate(1104.500000, 1743.500000) scale(-1, 1) translate(-1104.500000, -1743.500000) translate(1023.000000, 1615.000000)">
                <g id="Group-5" transform="translate(0.187107, 0.374214)">
                    <g id="A-Human/Standing" transform="translate(0.000000, 5.039999)">
                        <g id="Head/Front" transform="translate(31.063002, -0.001464)" fill="#B28B67">
                            <g id="Head" transform="translate(0.561909, 0.361007)">
                                <path d="M29.388578,7.04749071 C33.8533481,15.3813384 33.3228173,36.520834 27.5524999,38.0103251 C25.2512973,38.6043341 20.3490425,37.1492428 15.3596995,34.1376549 L18.4914548,56.3247865 L0,56.3247865 L6.36515913,26.6107663 C2.81527299,22.4376814 0.412722158,17.3236251 0.814222159,11.5932258 C1.97113532,-4.91879004 24.923808,-1.28635694 29.388578,7.04749071 Z" id="Head-[skin]"></path>
                            </g>
                        </g>
                        <g id="Bottom/Skinny-Jeans-1" transform="translate(0.255479, 120.724841)">
                            <g id="Seat-Stuff" transform="translate(0.550341, 32.406042)">
                                <polygon fill="#C5CFD6" points="21.2826178 0 80.8054947 0 88.6048875 95.6749798 0 95.6749798"></polygon>
                                <polygon fill-opacity="0.1" fill="#000000" points="21.2826178 0 39.589281 0 50.5462172 95.6749798 0 95.6749798"></polygon>
                            </g>
                            <path d="M125.332198,16.0154133 L125.554031,15.8856744 C131.923305,12.3123927 142.325388,17.1649357 142.318165,22.5595549 C142.296288,38.8894956 127.414574,115.852448 126.63758,120.717683 C125.860586,125.582917 117.537031,126.86801 117.052323,120.733627 C116.281071,110.972788 114.84818,77.1827973 116.281846,61.5426624 C116.873669,55.0863461 117.511034,48.9375849 118.138503,43.4520901 C110.330313,57.5736873 98.2530412,77.7935748 81.9074473,104.111181 L73.0342031,100.051473 C80.0020859,77.061643 85.7156451,60.8314344 90.1748807,51.3608477 C97.8101746,35.1449041 104.969511,21.2581102 107.982644,16.3665997 C112.722495,8.67194286 120.973103,11.3571377 125.332198,16.0154133 Z" id="Legs-[skin]" fill="#B28B67"></path>
                            <path d="M87.4932889,95.1524721 L91.4011726,16.8448248 C94.2373258,3.3053195 118.297174,9.60059718 118.290594,16.8571064 C118.275649,33.3316288 108.416177,90.2376305 108.411725,95.1459411 L87.4932889,95.1524721 Z" id="LegLower" fill="#5C5F74" transform="translate(102.891941, 52.016529) rotate(20.000000) translate(-102.891941, -52.016529) "></path>
                            <g id="Left-[shoe]" transform="translate(88.884750, 109.093776) rotate(30.000000) translate(-88.884750, -109.093776) translate(65.384750, 100.593776)" fill="#585858">
                                <path d="M2.83625892e-13,14.1407116 L0.764639123,1.79555294 C5.2414937,3.10678248 10.8488473,2.59240087 17.5866998,0.252408104 C24.8451736,5.40123997 33.9861848,8.91392819 45.0097335,10.7904727 C45.817915,10.9280596 46.3615483,11.69475 46.2239708,12.5029331 C46.2150582,12.5552896 46.203342,12.60713 46.188873,12.6582308 L45.1137082,16.4554289 L45.1137082,16.4554289 L17.5866998,16.4554289 L1.52927825,16.4554289 L2.83625892e-13,14.1407116 Z" id="Shoe"></path>
                            </g>
                            <g id="Right-[shoe]" transform="translate(113.040024, 111.625593)" fill="#585858">
                                <path d="M0,14.1407116 L0.764639123,1.79555294 C5.2414937,3.10678248 10.8488473,2.59240087 17.5866998,0.252408104 C24.8451736,5.40123997 33.9861848,8.91392819 45.0097335,10.7904727 C45.817915,10.9280596 46.3615483,11.69475 46.2239708,12.5029331 C46.2150582,12.5552896 46.203342,12.60713 46.188873,12.6582308 L45.1137082,16.4554289 L45.1137082,16.4554289 L17.5866998,16.4554289 L1.52927825,16.4554289 L0,14.1407116 Z" id="Shoe"></path>
                            </g>
                            <path d="M144.452671,28.1107194 C144.452671,48.6090623 134.731733,96.4236314 134.725582,100.93719 L113.810502,100.953278 C116.871821,58.2888095 117.957342,36.7267644 117.067065,36.2671426 C115.731648,35.57771 62.3240024,41.6070662 46.3061805,41.6070662 C23.2084021,41.6070662 13.6533138,27.0207725 12.8779774,-2.50621795e-13 L58.6338171,-2.50621795e-13 C67.9501169,0.99831204 116.544791,10.8137855 134.591889,14.1879922 C142.318161,15.6325478 144.452671,22.4639536 144.452671,28.1107194 Z" id="Leg-and-Butt" fill="#727589"></path>
                        </g>
                        <g id="Body/Long-Sleeve-1" transform="translate(12.567251, 39.709737)">
                            <path d="M115.025522,35.8069651 C118.363842,35.1187208 126.511725,29.050233 127.915646,30.2823653 C129.992656,32.1052257 128.140666,37.7024634 125.958582,40.4867894 C121.880269,45.6906912 118.503602,47.4702687 110.157644,49.800636 C105.793947,51.0190724 101.076018,53.4613231 96.0038584,57.1273883 L95.3349115,45.3220401 L107.082011,38.8596326 C108.730295,32.5788049 110.700618,29.4187081 112.99298,29.3793422 C114.753991,28.5587969 111.687203,36.4952094 115.025522,35.8069651 Z M94.8069816,8.90510846 C92.6500148,12.738953 90.5533869,16.8687268 91.6522765,18.0498514 C92.7511661,19.2309759 95.4913459,17.580997 96.8023046,19.5434455 C97.676277,20.8517444 92.9948966,22.9184885 82.7581633,25.7436776 L63.3260432,43.5611595 L53.4502558,32.9463218 L80.1933541,18.2784131 C84.6405676,12.9898246 89.0223116,8.88249464 93.3385862,5.95642324 C94.6575007,5.40501534 96.9639484,5.07126389 94.8069816,8.90510846 Z" id="Arms-[skin]" fill="#997659"></path>
                            <path d="M41.2928165,26.8165581 C50.5601478,28.184027 63.2074262,28.1812495 77.6968046,21.8983297 L81.0379894,34.262507 C70.5284704,41.8485282 56.586485,47.240805 45.4724056,45.1586688 C36.7138986,43.5178306 33.9053936,32.148976 41.2928165,26.8165581 Z" id="Sleeve" fill="#B3B8A0" transform="translate(59.043745, 33.753726) rotate(-9.000000) translate(-59.043745, -33.753726) "></path>
                            <path d="M41.0499712,2.86490801e-13 C42.3245263,16.39823 46.4129654,31.7274576 48.6438103,46.585799 C49.4777037,47.1479679 50.3653703,47.5904372 51.3053971,47.9137345 C60.9871103,51.2435041 78.2411013,48.4850307 103.06737,39.6383145 C106.990178,46.1752351 108.732546,52.0049264 108.294473,57.1273883 C84.621712,64.893271 65.1752654,67.8938074 50.0795283,66.9775798 C49.7968284,75.5205547 48.1225598,83.9997846 44.11505,92.5332632 C37.0426445,107.593044 16.5999776,93.1425691 2.67570393,95.8876866 C-6.04408566,67.5288155 10.8158914,56.003885 10.8294571,37.8582263 C10.8443131,17.9867964 17.3274844,2.86490801e-13 19.6717313,2.86490801e-13 L19.6717313,2.86490801e-13 Z" id="Clothes" fill="#CDD4B3"></path>
                        </g>
                        <path d="M104.577776,39.7097367 L115.418379,39.7097367 C116.238194,39.7097367 116.902785,40.3743278 116.902785,41.1941424 C116.902785,41.2454795 116.900122,41.296782 116.894806,41.3478432 L114.472882,64.6124938 C114.394157,65.368716 113.756764,65.9431989 112.996455,65.9431989 L101.455137,65.9431989 C100.635322,65.9431989 99.9707314,65.2786078 99.9707314,64.4587931 C99.9707314,64.3927496 99.9751389,64.3267798 99.9839246,64.2613233 L103.106563,40.9966727 C103.20547,40.259786 103.834281,39.7097367 104.577776,39.7097367 Z" id="Rectangle" fill="#2E2A2A"></path>
                        <path d="M104.604221,40.4813091 L114.792052,40.4813091 C115.20196,40.4813091 115.534255,40.8136046 115.534255,41.223512 C115.534255,41.2465953 115.533178,41.2696661 115.531028,41.292649 L113.359931,64.4985606 C113.324243,64.8800171 113.004078,65.1716265 112.620955,65.1716265 L101.804979,65.1716265 C101.395072,65.1716265 101.062776,64.8393309 101.062776,64.4294236 C101.062776,64.3997151 101.06456,64.3700334 101.068118,64.3405388 L103.86736,41.1346272 C103.912332,40.7618006 104.228692,40.4813091 104.604221,40.4813091 Z" id="Rectangle" fill="#FFFBFB"></path>
                    </g>
                    <path d="M31.0630022,45.5213086 C28.7421426,35.1814193 27.6373316,18.3398958 28.6471045,13.7184075 C29.3202864,10.6374153 31.1145179,8.72145437 34.0297992,7.9705248 C35.5375551,1.86226972 40.4003771,-0.73580924 48.6182652,0.176287915 C60.9450974,1.54443365 68.4687096,6.67090799 65.2196334,17.9513887 C62.2329682,17.9513887 58.6274251,16.8697281 53.6417083,18.7229611 C52.0597605,19.3109845 50.928305,21.1344965 50.2473419,24.1934972 L47.9943162,24.1934972 C45.8076598,20.5632997 43.6516026,19.2293011 41.5261446,20.1915016 C39.4006866,21.1537021 38.9175355,23.4908909 40.0766912,27.203068 L38.9668482,35.7281411 C38.5382043,38.3732504 38.1221205,40.1675755 37.7185967,41.1111164 C36.490579,43.9825335 34.2720475,45.4525976 31.0630022,45.5213086 Z" id="Hair" fill="#191847"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
  )
}
